import {
	AfterContentInit,
	Component,
	ContentChildren,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	QueryList
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators
} from '@angular/forms';
import {DropDownOptionComponent} from './drop-down-option/drop-down-option.component';
import {Subscription} from 'rxjs';
import {SubscriptionUtil} from '../../../../utils/subscriptions.util';

@Component({
	selector: 'aga-drop-down[id]',
	templateUrl: './drop-down.component.html',
	styleUrls: ['./drop-down.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DropDownComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: DropDownComponent,
			multi: true
		}
	]
})
export class DropDownComponent implements OnInit, AfterContentInit, ControlValueAccessor, Validator, OnDestroy {
	@ContentChildren(DropDownOptionComponent)
	dropDownOptions: QueryList<DropDownOptionComponent>;
	@Input() id: string;
	@Input() label: string;
	@Input() direction: 'upwards' | 'downwards' = 'downwards';
	@Input() style: 'original' | 'mini' = 'original';
	@HostBinding('class.disabled') isDisabled: boolean;
	@HostBinding('class.focused') isFocused: boolean;
	@HostBinding('class.is-empty') isEmpty = true;
	@HostBinding('class.is-open') showDropDownOptions: boolean;

	@HostBinding('class')
	get elementClasses() {
		return {
			'upwards': this.direction === 'upwards',
			'downwards': this.direction === 'downwards',
			'original': this.style === 'original',
			'mini': this.style === 'mini'
		};
	}

	@HostListener('document:click', ['$event.target'])
	public clickOutside(target: HTMLElement): void {
		const isInsideClick = this.elementRef.nativeElement.contains(target);
		if (!isInsideClick) {
			this.showDropDownOptions = false;
		}
	}

	onChange: any = () => {
	};
	onTouch: any = () => {
	};
	options: Array<any>;
	_value: string | {};
	isObject: boolean;
	selectedText: string;
	subscriptions: Array<Subscription>;
	isRequired: boolean;

	constructor(private elementRef: ElementRef) {
	}

	ngOnInit(): void {
		if (!this.id) {
			throw new Error(`Drop down attribute 'id' is required`);
		}
		this.subscriptions = [];
	}

	ngAfterContentInit(): void {
		this.processDropDownOptions(); // in case the options are available on initial load, else wait for them to be returned asynchronously by changes
		this.subscriptions.push(this.dropDownOptions.changes.subscribe(() => this.processDropDownOptions()));
	}

	processDropDownOptions(): void {
		this.options = [];
		this.dropDownOptions?.forEach((component: DropDownOptionComponent) => {
			if (component.option) {
				this.options.push(component.option);
			}
			this.markSelectedOption();
			this.subscriptions.push(component.select.subscribe((selectedOption: any) => {
				this.updateValue(selectedOption);
				this.markSelectedOption();
				this.showDropDownOptions = false;
			}));
		});
	}

	markSelectedOption(): void {
		this.selectedText = null;
		this.dropDownOptions?.forEach((component: DropDownOptionComponent) => {
			let isSelected: boolean;
			isSelected = JSON.stringify(this.value) === JSON.stringify(component.option);
			component.toggleSelected(Boolean(isSelected));
			if (isSelected) {
				this.selectedText = component['elementRef'].nativeElement.innerText;
				this.isEmpty = !this.selectedText;
			}
		});
	}

	set value(value) {
		this._value = value;
	}

	get value() {
		return this._value;
	}

	updateValue(newValue: any): void {
		this.value = newValue;
		this.onChange(newValue);
		this.onTouch(newValue);
		this.toggleDropDown();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	writeValue(value: any): void {
		this.value = value;
		this.markSelectedOption();
	}

	toggleDropDown(): void {
		if (!this.isDisabled && this.options?.length) {
			this.showDropDownOptions = !this.showDropDownOptions;
			this.onTouch();
		}
	}

	validate(control: AbstractControl): ValidationErrors | null {
		this.isRequired = control.hasValidator(Validators.required) || control.errors?.required;
		return null;
	}

	ngOnDestroy(): void {
		SubscriptionUtil.unsubscribe(this.subscriptions);
	}
}
