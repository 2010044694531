<section class="drop-down-select" (click)="toggleDropDown()">
	<div class="d-flex flex-column justify-content-center label-input">
		<label *ngIf="label">
			<span>{{label}}</span>
			<span class="required-star" *ngIf="isRequired"> *</span>
		</label>
		<input [id]="id+'-input'"
		       [readOnly]="true"
		       [ngModel]="selectedText">
	</div>
	<ng-container *ngIf="!isDisabled">
		<div *ngIf="style === 'original'" class="icon">
			<img *ngIf="(showDropDownOptions && direction === 'downwards') || (!showDropDownOptions && direction === 'upwards') else arrowDown"
			     src="assets/icons/svg/ic_Arrow-Up_24x24.svg"
			     alt="arrow-up-icon">
			<ng-template #arrowDown>
				<img src="assets/icons/svg/ic_Arrow-Down_24x24.svg" alt="arrow-down-icon">
			</ng-template>
		</div>
		<div *ngIf="style === 'mini'" class="icon mini">
			<span class="triangle">&#9698;</span>
		</div>
	</ng-container>
</section>

<section *ngIf="showDropDownOptions" class="drop-down-options">
	<ng-content select="aga-drop-down-option"></ng-content>
</section>
