import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Component({
	selector: 'aga-drop-down-option[id][option]',
	templateUrl: './drop-down-option.component.html',
	styleUrls: ['./drop-down-option.component.scss']
})
export class DropDownOptionComponent {
	@Input() option: any;
	@Input() @HostBinding('class.disabled') disabled: boolean;
	@Output() select: EventEmitter<any> = new EventEmitter<any>();

	@HostListener('click')
	click(): void {
		if (!this.disabled) {
			this.select.emit(this.option);
		}
	}

	constructor(private elementRef: ElementRef) {
	}

	toggleSelected(value: boolean): void {
		this.elementRef.nativeElement.classList.toggle('selected', value);
	}
}
